
































import {Component, Vue} from 'vue-property-decorator';
import {blogPostsClient} from '../../components/api/clients.context'

// eslint-disable-next-line no-unused-vars
import {BlogPost} from "@/components/api/api.clients";


@Component
export default class blog extends Vue {

    posts: BlogPost[] | null = null;

    async mounted() {
        this.posts = await blogPostsClient.getAll();


    }
}
