


















import {Component, Vue} from 'vue-property-decorator';
import {blogPostsClient} from '../../components/api/clients.context'

// eslint-disable-next-line no-unused-vars
import {BlogPost} from "@/components/api/api.clients";
@Component
export default class blogPost extends Vue {
    slug: string | null = null;
    post: BlogPost | null = null;

    async mounted() {
        this.slug = this.$route.params.slug;

        this.post = await blogPostsClient.bySlug(this.slug);
    }
}
